<template>
  <f7-page class="video-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <HomeNavigationComponent search="video" @searched="onSearch" />
    </template>

    <section class="video-container">
      <VideoListComponent ref="videoListComponent" mode="video" :size="16" />
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, defineAsyncComponent } from "vue";

// import HomeNavigationComponent from "@/components/navigations/HomeNavigationComponent.vue";
// import VideoListComponent from "@/components/VideoListComponent.vue";

const HomeNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "home-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/HomeNavigationComponent.vue"));
const VideoListComponent = defineAsyncComponent(() => import(/* webpackChunkName: "video-list" */ /* webpackMode: "lazy" */ "@/components/VideoListComponent.vue"));

export default defineComponent({
  name: "VideoPage",
  components: {
    HomeNavigationComponent,
    VideoListComponent,
  },
  props: { f7router: Object },
  setup() {
    const videoListComponent = ref(false);
    const showPreloader = ref(false);

    return { videoListComponent, showPreloader };
  },
  methods: {
    async loadMore() {
      this.showPreloader = true;
      await this.$refs.videoListComponent.loadMore();
      this.showPreloader = false;
    },
    onSearch(searchValue) {
      this.$refs.videoListComponent.search(searchValue);
    },
  },
});
</script>
